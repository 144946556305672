import { createApp } from "vue";
import App from "./App.vue";
import mitt from "mitt";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VuesticPlugin } from "vuestic-ui";
import "vuestic-ui/dist/vuestic-ui.css";
import VueScreen from "vue-screen";
import VueGtag from "vue-gtag";

const emitter = mitt();
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VuesticPlugin, {
  colors: {
    // Default colors
    primary: "#20DC86",
    secondary: "#E5FBF3",
    success: "#40e583",
    info: "#2c82e0",
    danger: "#e34b4a",
    warning: "#ffc200",
    gray: "#777777",
    dark: "#34495e",
    // Custom colors
    pastellblau: "#C0CEFF",
    seethrough: "transparent",
    whitethrough: "#f0f0f091",
  },
});
app.use(VueScreen);
app.config.globalProperties.emitter = emitter;
app.use(VueGtag, {
  bootstrap: false,
  config: { id: "G-19Z240FCCJ" },
});
app.mount("#app");
