<template>
  <div :class="$screen.width < 768 ? 'pa-2' : 'pa-4'">
    <h1
      style="
        font-size: 2rem;
        line-height: 3.25rem;
        font-weight: bold;
        text-align: left;
      "
      class="$screen.width < 768 ? 'pl-2' : 'pl-4'"
    >
      Dienstanbieter
    </h1>
    <div class="row mt-0 content-paragraph">
      <div class="flex lg12 md12 sm12 xs12">
        <p style="font-weight: bold; color: black">Zentek GmbH & Co. KG</p>
        <p class="mb-3">
          Zentek Verwaltungs GmbH als persönlich haftende Gesellschafterin<br />
          vertreten durch den Geschäftsführer<br />
          Dipl.-Kaufmann Christoph Ley<br />
          Ettore-Bugatti-Str. 6-14<br />
          51149 Köln
        </p>
        <p class="mb-3">
          Telefon: 02203 8987-500<br />
          Fax: 02203 8987-777<br />
          E-Mail:
          <a href="mailto:recycling@zentek.de">recycling@zentek.de</a><br />
        </p>
        <p>
          Handelsregister: Amtsgericht Köln<br />
          Registernummer: HRA Köln 13559<br />
          UstID: DE 173 788 828
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          Aufsichtsbehörden für das Duale System Zentek sind:
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Schleswig-Holstein
        </p>
        <p>
          Ministerium für Landwirtschaft, Umwelt und ländliche Räume des Landes
          Schleswig-Holstein, Mercatorstraße 3, 5, 7, 24106 Kiel
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Freie und Hansestadt Hamburg
        </p>
        <p>
          Behörde für Stadtentwicklung und Umwelt, Billstraße 84, 20539 Hamburg
        </p>
        <!-- starting -->
        <p style="font-weight: bold; color: black" class="mt-4">
          Niedersachsen
        </p>
        <p>
          Niedersächsisches Umweltministerium, Archivstraße 2, 30169 Hannover
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Freie Hansestadt Bremen
        </p>
        <p>
          Der Senator für Bau, Umwelt und Verkehr, Ansgaritorstraße 2, 28195
          Bremen
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Nordrhein-Westfalen
        </p>
        <p>
          Ministerium für Umwelt und Naturschutz, Landwirtschaft und
          Verbraucherschutz das Landes Nordrhein-Westfalen, Schwannstraße 3,
          40476 Düsseldorf
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">Hessen</p>
        <p>
          Hessisches Ministerium für Umwelt, ländlichen Raum und
          Verbraucherschutz, Mainzer Straße 80, 65189 Wiesbaden
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Rheinland-Pfalz
        </p>
        <p>
          Ministerium für Umwelt, Landwirtschaft, Ernährung, Weinbau und
          Forsten, Kaiser-Friedrich-Straße 1, 55116 Mainz
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Baden-Württemberg
        </p>
        <p>
          Umweltministerium Baden-Württemberg, Kernerplatz 9, 70182 Stuttgart
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Freistaat Bayern
        </p>
        <p>
          Bayerisches Staatsministerium für Umwelt, Gesundheit und
          Verbraucherschutz, Rosenkavalierplatz 2, 81925 München
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">Saarland</p>
        <p>
          Landesamt für Umwelt- und Arbeitsschutz, Don-Bosco-Straße 1, 66119
          Saarbrücken
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">Berlin</p>
        <p>
          Senatsverwaltung für Gesundheit, Umwelt und Verbraucherschutz,
          Brückenstraße 6, 10179 Berlin
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">Brandenburg</p>
        <p>
          Ministerium für Ländliche Entwicklung, Umwelt und Verbraucherschutz
          des Landes Brandenburg, Heinrich-Mann-Allee 103, 14473 Potsdam
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Mecklenburg-Vorpommern
        </p>
        <p>
          Landesamt für Umwelt, Naturschutz und Geologie Mecklenburg-Vorpommern,
          Goldberger Straße 12, 18273 Güstrow
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Freistaat Sachsen
        </p>
        <p>
          Sächsisches Staatsministerium für Umwelt und Landwirtschaft,
          Archivstraße 1, 01097 Dresden
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">
          Sachsen-Anhalt
        </p>
        <p>
          Ministerium für Landwirtschaft und Umwelt das Landes Sachsen-Anhalt,
          Olvenstedter Straße 4, 39012 Magdeburg
        </p>
        <p style="font-weight: bold; color: black" class="mt-4">Thüringen</p>
        <p>
          Ministerium für Landwirtschaft, Naturschutz und Umwelt,
          Beethovenstraße 3, 99096 Erfurt
        </p>
        <p class="mt-4">
          Jede Verwertung innerhalb eines gewerblichen Unternehmens, wie
          Vervielfältigung jeder Art, Übersetzungen, Mikroverfilmungen und die
          Einspeicherung und Verarbeitung in elektronischen Systemen bedürfen
          der schriftlichen Einwilligung der Zentek GmbH & Co. KG.
        </p>
        <p class="mt-4">
          Auch wenn wir große Sorgfalt darauf verwenden, Ihnen qualitativ gute
          Links anzubieten, erklären wir hiermit, dass wir für die Inhalte der
          verlinkten Seiten keine Verantwortung übernehmen und uns diese nicht
          zu eigen machen. Diese Erklärung gilt für alle auf dieser Webseite
          angebrachten Links. In keinem Fall sind wir verantwortlich für die
          Inhalte von Internetseiten, die auf uns verweisen. Wir sind auch nicht
          in der Lage, Verweise auf uns festzustellen oder zu prüfen. Zentek
          GmbH & Co. KG hat ferner keinen Einfluss darauf, wie die hier
          angebotenen Informationen vom Nutzer verwendet, weitergegeben oder
          verändert weitergegeben werden. Zentek GmbH & Co. KG kann daher für
          entstandene Schäden, entgangene Vorteile oder sonstige mittelbare oder
          unmittelbare Folgen, welche aus der Nutzung der hier angebotenen
          Informationen entstehen, nicht haftbar gemacht werden.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprintPage",
};
</script>

<style lang="css" scoped>
@media (max-width: 767px) {
  h1 {
    font-size: 1rem !important;
  }
  .content-paragraph {
    font-size: 0.7rem !important;
    text-align: left !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
  }
}
</style>
