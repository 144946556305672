
import { defineComponent } from "vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import ImprintPage from "@/components/ImprintPage.vue";

export default defineComponent({
  name: "DualSystem",
  props: {
    msg: String,
  },
  components: {
    PrivacyPolicy,
    ImprintPage,
  },
  data: () => {
    return {
      privacyPolicy: false,
      imprint: false,
    };
  },
});
