
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    question: String,
    answer: String,
  },
  data: () => {
    return {
      visible: false,
    };
  },
});
