<template>
  <div class="base-home contact mb-5" id="contact">
    <div class="home">
      <div class="contact-content text-center mb-5">
        <h2 style="font-size: 2rem; line-height: 3rem; font-weight: bold">
          Jetzt für Ihre Beteiligung an 100&nbsp;% Verpackungsrecycling
          <br />mit uns in Kontakt treten
        </h2>
        <p
          style="color: #777777; font-size: 0.85rem; line-height: 1.5rem"
          class="mt-4 mb-2 paragraph"
        >
          Sie möchten gerne Ihren Beitrag zu 100&#8209;prozentigem Recycling von
          Kunststoffverpackungen leisten?
        </p>
        <p
          style="color: #777777; font-size: 0.85rem; line-height: 1.5rem"
          class="my-2 paragraph"
        >
          Egal, ob Sie bereits Kunde des Dualen Systems Zentek sind oder sich
          für die Lizenzierung Ihrer Kunststoffverpackungen gemäß VerpackG
          bislang noch keinem dualen System angeschlossen haben – gerne
          besprechen wir persönlich mit Ihnen, wie Sie das Angebot von
          recycling.de nutzen können.
        </p>
        <p
          style="color: #777777; font-size: 0.85rem; line-height: 1.5rem"
          class="mt-2 mb-4 paragraph"
        >
          Bei Fragen sind wir für Sie auch von Mo-Fr von 7-17 Uhr unter unserer
          kostenlosen Hotline 02203 89 87 – 500 erreichbar.
        </p>
        <va-card :bordered="false" class="contact-form" v-if="!submitted">
          <va-form ref="contactForm" class="pa-5">
            <div class="row">
              <div class="flex lg2 md2 sm12 xs12">
                <va-card-title
                  class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                  style="color: black; text-transform: none; font-size: 0.75rem"
                >
                  Anrede</va-card-title
                >
                <va-select
                  v-model="nameTitle"
                  stateful
                  outline
                  :options="['Herr', 'Frau']"
                  class="mb-4 mr-5 d-block"
                  placeholder="Anrede"
                />
              </div>
              <div class="flex lg4 md4 sm12 xs12 offset--lg1 offset--md1">
                <va-card-title
                  class="text-left d-block pr-0 pl-2 pt-0 pb-2"
                  style="color: black; text-transform: none; font-size: 0.75rem"
                >
                  Vorname</va-card-title
                >
                <va-input
                  class="mb-4 d-block"
                  outline
                  v-model="firstName"
                  placeholder="Vorname"
                  stateful
                  :rules="[
                    (value) =>
                      value !== '' || 'Bitte geben Sie Ihren Vornamen ein',
                  ]"
                />
              </div>
              <div class="flex lg4 md4 sm12 xs12 offset--lg1 offset--md1">
                <va-card-title
                  class="text-left d-block pr-0 pl-2 pt-0 pb-2"
                  style="color: black; text-transform: none; font-size: 0.75rem"
                >
                  Nachname</va-card-title
                >
                <va-input
                  class="mb-4 mr-0 d-block"
                  outline
                  v-model="lastName"
                  placeholder="Nachname"
                  stateful
                  :rules="[
                    (value) =>
                      value !== '' || 'Bitte geben Sie Ihren Nachnamen ein',
                  ]"
                />
              </div>
            </div>
            <div class="d-block">
              <va-card-title
                class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                style="color: black; text-transform: none; font-size: 0.75rem"
              >
                Firma</va-card-title
              >
              <va-input
                class="mb-4 d-block"
                outline
                v-model="companyName"
                placeholder="Firma"
                stateful
                :rules="[
                  (value) =>
                    value !== '' || 'Bitte geben Sie den Firmennamen ein',
                ]"
              />
            </div>
            <div class="row">
              <div class="flex lg6 md6 sm12 xs12">
                <va-card-title
                  class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                  style="color: black; text-transform: none; font-size: 0.75rem"
                >
                  E-Mail Adresse</va-card-title
                >
                <va-input
                  class="mb-4 d-block"
                  outline
                  v-model="email"
                  placeholder="E-Mail Adresse"
                  stateful
                  :rules="emailRules"
                />
              </div>
              <div class="flex lg5 md5 sm12 xs12 offset--lg1 offset--md1">
                <va-card-title
                  class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                  style="color: black; text-transform: none; font-size: 0.75rem"
                >
                  Telefon (optional)</va-card-title
                >
                <va-input
                  class="mb-4 d-block"
                  outline
                  v-model="phone"
                  placeholder="Telefon (optional)"
                  stateful
                />
              </div>
            </div>
            <div class="d-block">
              <va-card-title
                class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                style="color: black; text-transform: none; font-size: 0.75rem"
              >
                Sind Sie bereits Kunde des Dualen Systems Zentek?</va-card-title
              >
              <div class="d-flex pl-1 mt-2">
                <va-radio
                  v-model="existingCustomer"
                  option="Ja"
                  color="primary"
                  class="d-inline mr-4"
                  style="font-size: 0.75"
                />
                <va-radio
                  v-model="existingCustomer"
                  option="Nein"
                  color="primary"
                  class="d-inline mr-4"
                />
                <va-radio
                  v-model="existingCustomer"
                  option="Unklar"
                  color="primary"
                  class="d-inline"
                />
              </div>
            </div>
            <div class="d-block mt-4">
              <va-card-title
                class="text-left d-block pr-0 pl-1 pt-0 pb-2"
                style="color: black; text-transform: none; font-size: 0.75rem"
              >
                Kommentar (optional)</va-card-title
              >
              <va-input
                class="d-block"
                v-model="message"
                type="textarea"
                outline
                placeholder="Ihre Nachricht an uns"
              />
            </div>
            <div class="mt-3 d-flex">
              <va-checkbox class="d-inline" v-model="acceptPrivacy" /><span
                class="content-paragraph mt-0"
                style="cursor: pointer"
                @click="privacyPolicy = true"
                >Ich akzeptiere die Datenschutzbestimmungen</span
              >
            </div>
          </va-form>
          <div :id="recaptchaId" data-size="invisible" :key="divKey"></div>
          <va-button
            class="mb-5 send-contact-button"
            :rounded="false"
            color="primary"
            size="medium"
            text-color="white"
            @click="validateForm"
            :disabled="emailSent"
          >
            Jetzt mit uns in Kontakt treten
          </va-button>
        </va-card>
        <va-card :bordered="false" class="contact-form pb-5" v-else>
          <div style="width: 40%; margin-left: 35%" class="pt-5 mb-5">
            <va-image
              contain
              src="https://storage.googleapis.com/recyclede/submitted.png"
            />
          </div>
          <div style="width: 60%; margin-left: 20%" class="mb-5">
            <h2
              style="
                font-size: 2rem;
                line-height: 3rem;
                font-weight: bold;
                text-align: center !important;
              "
              class="thank-you"
            >
              Vielen Dank für Ihr Interesse an 100&nbsp;% Verpackungsrecycling!
            </h2>
            <p class="content-paragraph" style="text-align: center !important">
              Wir haben Ihre Anfrage erhalten und werden Sie in Kürze
              kontaktieren.
            </p>
          </div>
        </va-card>
        <va-modal
          v-model="privacyPolicy"
          hide-default-actions
          overlay-opacity="0.2"
          :size="$screen.width < 768 ? 'medium' : 'large'"
          :max-width="$screen.width < 768 ? '300' : ''"
          :mobile-fullscreen="false"
          z-index="99999"
        >
          <privacy-policy />
        </va-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";
import { emailRules, validateEmail } from "@/helpers";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";

export default defineComponent({
  name: "ContactPage",
  props: {
    msg: String,
  },
  components: {
    PrivacyPolicy,
  },
  data: () => {
    return {
      firstName: "",
      lastName: "",
      nameTitle: "",
      companyName: "",
      email: "",
      phone: "",
      existingCustomer: "Nein",
      message: "",
      acceptPrivacy: false,
      emailSent: false,
      divKey: 0,
      recaptchaId: "rewidget",
      emailRules: emailRules,
      submitted: false,
      privacyPolicy: false,
    };
  },
  methods: {
    validateForm() {
      if (
        this.nameTitle !== "" &&
        this.firstName !== "" &&
        this.lastName !== "" &&
        this.companyName !== "" &&
        this.email &&
        validateEmail(this.email) &&
        this.acceptPrivacy
      ) {
        const siteKey = process.env.VUE_APP_SITE_KEY;
        window.grecaptcha.render(this.recaptchaId, {
          sitekey: siteKey,
          callback: this.sendInquiry,
        });
        if (window.grecaptcha) {
          window.grecaptcha.execute();
        }
      } else {
        if (!validateEmail(this.email)) {
          this.$emit("email-wrong");
        } else if (!this.acceptPrivacy) {
          this.$emit("pp-required");
        } else {
          this.$emit("incomplete-form");
        }
      }
    },
    sendInquiry(token) {
      this.divKey += 1;
      this.recaptchaId = `rewidget${this.divKey}`;
      const contactPayload = {
        firstName: this.firstName,
        lastName: this.lastName,
        nameTitle: this.nameTitle,
        companyName: this.companyName,
        email: this.email,
        phone: this.phone,
        existingCustomer: this.existingCustomer,
        message: this.message,
        token: token,
      };
      this.submitted = true;

      axios({
        url: ApiUrl.SEND_MESSAGE,
        method: "POST",
        data: contactPayload,
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_MAIL_SERVICE_AUTH}`,
        },
      })
        .then(() => {
          this.$emit("successfully-send-email");
          this.resetForm();
          this.emailSent = true;
        })
        .catch(() => {
          this.$emit("failed-send-email");
        });
    },
    resetForm() {
      this.firstName = "";
      this.lastName = "";
      this.nameTitle = "";
      this.companyName = "";
      this.email = "";
      this.phone = "";
      this.existingCustomer = "Nein";
      this.message = "";
    },
  },
});
</script>
<style lang="css">
.va-input-wrapper * {
  border-radius: 5px !important;
}
.va-input_labeled .va-input__content-wrapper {
  padding-top: 8px !important;
  align-items: center !important;
}
.va-select-option-list__option,
.va-input__content__input {
  font-size: 0.85rem !important;
}
.va-input__label {
  font-size: 0.75rem !important;
  color: black !important;
  text-transform: none !important;
}
.va-input__container {
  min-height: 50px !important;
}
.va-radio *,
.va-checkbox * {
  font-size: 0.75rem !important;
}
.va-radio + .va-radio {
  margin-top: 0 !important;
}
.va-radio__text {
  font-weight: bold !important;
}
.send-contact-button {
  width: 50% !important;
  height: 50px;
  font-size: 1rem !important;
}
.contact-form {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .contact-form {
    width: 100%;
  }
}
@media (max-width: 720px) {
  .send-contact-button {
    width: 85% !important;
  }
}
@media (max-width: 600px) {
  .paragraph {
    text-align: justify !important;
  }
}
@media (max-width: 576px) {
  .va-modal--size-small {
    max-width: 75vw !important;
  }
}
</style>
