
import { defineComponent } from "vue";
import MediaPage from "@/components/MediaPage.vue";
import BenefitPage from "@/components/BenefitPage.vue";
import DualSystem from "@/components/DualSystem.vue";
import ExtraInfo from "@/components/ExtraInfo.vue";
import ContactPage from "@/components/ContactPage.vue";
import FooterDrawer from "@/components/FooterDrawer.vue";
import CookieModal from "@/components/CookieModal.vue";

export default defineComponent({
  name: "Home",
  components: {
    MediaPage,
    BenefitPage,
    DualSystem,
    ExtraInfo,
    ContactPage,
    FooterDrawer,
    CookieModal,
  },
  data: () => {
    return {
      showMore: false,
      cookiesDecided: false,
      successObject: {
        message: "Ihre Nachricht wurde erfolgreich gesendet",
        color: "success",
        postion: "top-right",
      },
      failedObject: {
        message:
          "Leider ist ein interner Server-Fehler aufgetreten. Wir arbeiten daran!",
        color: "danger",
        postion: "top-right",
      },
      formIncomplete: {
        message: "Bitte geben Sie die Pflichtfelder ein.",
        color: "danger",
        postion: "top-right",
      },
      wrongEmail: {
        message: "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
        color: "danger",
        postion: "top-right",
      },
      ppRequired: {
        message:
          "Akzeptieren Sie bitte unsere Datenschutzbestimmungen vor dem Senden der Nachricht.",
        color: "danger",
        postion: "top-right",
      },
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.localStorage.getItem("cookies") === null
      ? (this.cookiesDecided = false)
      : (this.cookiesDecided = true);
  },
  methods: {
    handleScroll() {
      const menubar = document.getElementById("menubar") as HTMLElement;
      const contactButton = document.getElementsByClassName(
        "header-button"
      )[0] as HTMLElement;
      if (window.scrollY !== 0) {
        menubar.style.background = "white none repeat scroll 0% 0%";
        menubar.style.borderBottom = "thin solid rgb(236, 236, 236)";
        menubar.style.transition = "transform 0.5s";
        contactButton.style.color = "#ffffff";
        contactButton.style.background =
          "rgb(32, 220, 134) none repeat scroll 0% 0%";
        contactButton.style.borderColor = "none";
      } else {
        menubar.style.background = "transparent none repeat scroll 0% 0%";
        menubar.style.borderBottom = "none";
        contactButton.style.color = "rgb(32, 220, 134)";
        contactButton.style.background =
          "rgba(0, 0, 0, 0) none repeat scroll 0% 0%";
        contactButton.style.borderColor = "rgb(32, 220, 134)";
      }
    },
    scrollTo(id: string) {
      const yOffset = -100;
      const element = document.getElementById(id) as HTMLElement;
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    },
  },
});
