
import { defineComponent } from "vue";

export default defineComponent({
  name: "BenefitPage",
  props: {
    msg: String,
  },
  data: () => {
    return {
      expand: [false, false, false, false, false, false, false, false],
    };
  },
});
