<template>
  <div class="base-home media my-5" id="media">
    <div class="home">
      <div class="row banner-content">
        <div class="flex sm12 md6 text-left xs12"></div>
        <div class="flex sm12 md6 text-left xs12">
          <h2 style="font-size: 2rem; line-height: 3rem; font-weight: bold">
            Maximales Verpackungsrecycling:<br />
            das Prinzip von
            <span style="color: #20dc86">recycling.de</span>
          </h2>
        </div>
      </div>
      <div class="row banner-content">
        <div class="flex text-left sm12 md6 xs12 media-wrap">
          <video
            controls
            controlslist="nodownload"
            preload="metadata"
            class="media-image"
            width="550"
            height="350"
            poster="https://storage.googleapis.com/recyclede/interview.png"
          >
            <source
              src="https://storage.googleapis.com/recyclede/interview.webm"
              type='video/webm; codecs="vp8, vorbis"'
            />
            <source
              src="https://storage.googleapis.com/recyclede/interview.mp4"
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
          </video>
        </div>
        <div class="flex sm12 md6 text-left xs12">
          <p class="content-paragraph">
            Bei der Trennung von Kunststoffverpackungen in Sortieranlagen fallen
            neben den aussortierten und zu recycelnden Wertstoffen leider immer
            noch zu viele Mischkunststoffe und Sortierreste an. Das liegt zum
            einen daran, dass viele Verpackungen nicht aus einer der gut
            recycelbaren Kunststoff-Monofraktionen bestehen und für sie aktuell
            noch keine Verwertungswege existieren. Zum anderen sind Verpackungen
            oft schlichtweg zu klein, um im automatisierten Sortierprozess
            richtig erkannt zu werden.
          </p>
          <p class="content-paragraph">
            Diese gemischte Kunststofffraktion wird nach der Sortierung meistens
            zu Ersatzbrennstoff aufbereitet und landet z. B. in Zementwerken in
            der energetischen Verwertung. recycling.de möchte anders mit dieser
            gemischten Kunststofffraktion umgehen, da sie noch recycelbare
            Kunststoffe enthält. Abfälle, die normalerweise der energetischen
            Verwertung zugeführt würden, können über recycling.de ebenfalls
            werkstofflich verwertet werden.
          </p>
          <p class="content-paragraph" v-if="showMore">
            Sie möchten sich gerne für diesen wichtigen Zusatzschritt einsetzen
            und daran beteiligen, dass im Recycling von Kunststoffverpackungen
            nicht nur das gesetzliche Soll von 63 Prozent erreicht wird, sondern
            ganze 100 Prozent? Oder Sie sind Hersteller oder Inverkehrbringer
            von Kunststoffverpackungen, die nur schwer recycelbar sind, und
            möchten durch Ihre Beteiligung Verantwortung übernehmen und
            Engagement zeigen? Dann sind Sie hier genau richtig.
            <br /><br />
            recycling.de ist unsere Lösung für noch mehr Recycling und eine
            besser funktionierende Kreislaufwirtschaft.
          </p>
          <va-button
            flat
            @click="showMore = !showMore"
            icon="add"
            class="mr-4 more"
            v-if="!showMore"
            >Weiterlesen</va-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MediaPage",
  props: {
    msg: String,
  },
  data: () => {
    return {
      showMore: false,
    };
  },
});
</script>
