<template>
  <va-modal
    v-model="dialog"
    hide-default-actions
    overlay-opacity="0.2"
    size="small"
    :max-width="$screen.width < 768 ? '300' : ''"
    :mobile-fullscreen="false"
    z-index="99999"
  >
    <div :class="$screen.width < 768 ? 'pa-2' : 'pa-4'">
      <h2
        style="
          font-size: 2rem;
          line-height: 3rem;
          font-weight: bold;
          text-align: left;
        "
      >
        Cookies
      </h2>
      <p class="cookies-paragraph">
        Diese Internetseite verwendet Cookies zu Analyse- und Statistikzwecken,
        um Ihr Nutzererlebnis steigern zu können. Genaue Informationen bekommen
        Sie über unsere
        <a
          href="#/datenschutz"
          target="_blank"
          rel="noopener"
          style="text-decoration: none"
          >Datenschutzbestimmung</a
        >. Wenn Sie damit einverstanden sind, freuen wir uns über Ihre
        Zustimmung.
      </p>
      <div class="row" style="max-width: 60%; margin-left: 20%">
        <va-button
          class="cookies-button"
          style="margin-right: 5%"
          outline
          :rounded="false"
          color="#A9A9A9"
          size="medium"
          text-color="#A9A9A9"
          @click="decline"
        >
          Ablehnen
        </va-button>
        <va-button
          class="cookies-button"
          style="margin-left: 5%"
          :rounded="false"
          color="primary"
          size="medium"
          text-color="white"
          @click="accept"
          data-cy="click-agree-cookie"
        >
          Okay
        </va-button>
      </div>
    </div>
  </va-modal>
</template>

<script>
/* eslint-disable */
import { bootstrap } from "vue-gtag";
import { CookieValue } from "@/helpers";

export default {
  name: "PrivacyPolicy",
  data: () => {
    return {
      dialog: true,
    };
  },
  methods: {
    accept() {
      window.localStorage.setItem("cookies", CookieValue.ACCEPT);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      bootstrap().then(() => {});
      this.dialog = false;
    },
    decline() {
      window.localStorage.setItem("cookies", CookieValue.REJECT);
      this.dialog = false;
    },
  },
};
</script>

<style lang="css" scoped>
.cookies-button {
  width: 45% !important;
  height: 50px;
  font-size: 1rem !important;
}

.cookies-paragraph {
  margin-top: 20px;
  color: #777777;
  font-size: 0.85rem !important;
  line-height: 1.5rem !important;
  text-align: justify;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  h1 {
    font-size: 1rem !important;
  }
}
</style>
