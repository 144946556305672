
import { defineComponent } from "vue";

export default defineComponent({
  name: "DualSystem",
  props: {
    msg: String,
  },
  data: () => {
    return {
      appendGif: false,
    };
  },
});
