import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "text-left mt-5 row" }
const _hoisted_2 = { class: "flex lg1 md1 sm1 xs2" }
const _hoisted_3 = { class: "flex lg11 md11 sm11 xs10" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_divider = _resolveComponent("va-divider")!
  const _component_va_icon = _resolveComponent("va-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_va_divider),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.visible)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 0,
              name: "remove_circle",
              class: "mr-2",
              color: "primary",
              style: {"font-size":"24px"},
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = false))
            }))
          : _createCommentVNode("", true),
        (!_ctx.visible)
          ? (_openBlock(), _createBlock(_component_va_icon, {
              key: 1,
              name: "add_circle",
              class: "mr-2",
              color: "primary",
              style: {"font-size":"24px"},
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = true))
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", {
          style: {"color":"black","font-size":"1rem","line-height":"1.75rem","font-weight":"bold"},
          class: "mb-4",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible))
        }, _toDisplayString(_ctx.question), 1),
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              style: {"color":"#777777","font-size":"0.85rem","line-height":"1.75rem","text-align":"justify"},
              class: "mt-4 mb-5",
              innerHTML: _ctx.answer
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}