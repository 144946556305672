
import { defineComponent } from "vue";
import Qa from "./Qa.vue";

export default defineComponent({
  name: "DualSystem",
  props: {
    msg: String,
  },
  components: {
    Qa,
  },
  data: () => {
    return {
      content: [
        {
          question: "Was ist recycling.de?",
          answer:
            "recycling.de ist die Premiumdienstleistung des Dualen Systems Zentek. Hersteller und Inverkehrbringer von Verkaufsverpackungen sind laut Verpackungsgesetz (VerpackG) dazu verpflichtet, sich einem dualen System anzuschließen, um ihre Verpackungen zu lizenzieren. Das beauftragte duale System organisiert dann die Sammlung, Sortierung und Verwertung dieser Verpackungen und muss die gesetzlichen Recyclingquoten einhalten. Die gesetzlich vorgegebene Recyclingquote für Kunststoff liegt bei 63 Prozent. Wir finden, hier ist noch viel Luft nach oben: Die Nachfrage seitens unserer Kunden nach Optionen für mehr Recycling ist groß – genau wie unser Anspruch, die Möglichkeit anzubieten, durch einen finanziellen Beitrag mehr Verantwortung für mehr Recycling zu übernehmen.<br />Als Zusatzleistung für mehr Recycling und eine noch besser funktionierende Kreislaufwirtschaft, bietet recycling.de Herstellern und Inverkehrbringern von Kunststoffverpackungen die Option der Beteiligung an einer 100-prozentigen werkstofflichen Verwertung von Kunststoffverpackungen. recycling.de ermöglicht dies sowohl über die zusätzliche Nachsortierung von Mischkunststoffen vor der Ersatzbrennstoffaufbereitung als auch durch Verwertungsverfahren für Mischkunststoffe, bei denen einfache Kunststoffprodukte entstehen. recycling.de ist der Extraweg mit Extraeffekt.",
        },
        {
          question: "An wen richtet sich recycling.de?",
          answer:
            "recycling.de richtet sich an Hersteller und Inverkehrbringer von Kunststoffverpackungen, die sich eine 100-prozentige werkstoffliche Verwertung wünschen. Sie geben sich mit der Erfüllung der gesetzlich vorgegebenen Kunststoff-Recyclingquote von 63 Prozent nicht zufrieden? Wir uns auch nicht! Wer sich im Verhältnis zur lizenzierten Kunststoffverpackungsmenge daran beteiligen möchte, dass die Recyclingquote noch um weitere 37 Prozent ergänzt wird – für 100 Prozent Recycling – ist bei recycling.de genau richtig. Besonders im Fokus sind damit auch Hersteller und Inverkehrbringer von nur schwer recycelbaren Kunststoffverpackungen, die durch ihre Beteiligung Verantwortung übernehmen und Engagement zeigen möchten.",
        },
        {
          question: "Warum sollte ich den Service von recycling.de nutzen?",
          answer:
            "Mit der Nutzung des Services von recycling.de tragen Sie dazu bei, dass im Recycling von Kunststoffverpackungen nicht nur das gesetzliche Soll von 63 Prozent erfüllt wird, sondern ganze 100 Prozent. Entscheiden Sie sich bewusst dafür, noch mehr Verantwortung zu übernehmen: Wenn Sie gut recycelbare Verpackungen auf den Markt bringen, leisten Sie bereits Großes, können Ihre Marke aber mit recycling.de zusätzlich stärken und zeigen, dass Sie guten Beispiels vorangehen und maximales Recycling fördern. Wenn Sie als Hersteller und Inverkehrbringer schwer recycelbare Kunststoffverpackungen in Umlauf bringen, weil sich dies aus produktspezifischen Gründen (z. B. zum Schutz des Produkts) nur schwer vermeiden lässt, so können Sie mit Ihrer Beteiligung an 100 Prozent werkstofflicher Verwertung gezielt Verantwortung übernehmen und Engagement zeigen. Mit recycling.de fördern Sie eine noch besser funktionierende Kreislaufwirtschaft und eine nachhaltige Ressourcennutzung. Beteiligen Sie sich im Verhältnis zu Ihren lizenzierten Verpackungsmengen an dem Service von recycling.de, so ermöglichen Sie – äquivalent zu Ihren Mengen – mehr Recycling von Kunststoffverpackungen und tun nicht nur Gutes für die Umwelt, sondern auch für sich: Nutzen Sie Ihr Engagement mit recycling.de für die Nachhaltigkeitskommunikation – so sprechen Sie gezielt umweltbewusste Verbraucherinnen und Verbraucher an und stärken Ihre Marke auf dem Markt.",
        },
        {
          question:
            "Wie gestaltet sich der Prozess für mehr Recycling mit recycling.de?",
          answer:
            "100 Prozent werkstoffliches Recycling wird mit recycling.de auf zwei Wegen erreicht: Einerseits werden die bei der Sortierung von Kunststoffverpackungen übrigbleibenden Mischkunststoffe nachträglich in einem Extraschritt nochmals sortiert. Diese Mischkunststoffe fallen in Sortieranlagen an, da viele Verpackungen nicht aus einer der gut recycelbaren Kunststoff-Monofraktionen bestehen und so auch nicht dem Recycling zugeführt werden können. Üblicherweise werden diese gemischten Kunststofffraktionen dann als Ersatzbrennstoffe aufbereitet und der energetischen Verwertung zugeführt. Das Problem hierbei ist, dass diese gemischten Kunststofffraktionen oft noch recyclingfähige Kunststoffverpackungen enthalten, die aber von den Sortiermaschinen aufgrund ihrer Größe nicht automatisch erkannt werden. Selbst in modernsten Sortieranlagen passiert es hin und wieder, dass grundsätzlich gut recyclingfähige Verpackungen im automatisierten Sortierprozess nicht erkannt werden und bei den Mischkunststoffen landen. Mit dem zusätzlichen Sortierschritt von recycling.de wird daher das Recyclingpotenzial der gemischten Kunststofffraktion noch mehr ausgeschöpft, damit wertvolle recycelbare Verpackungen nicht direkt in der Verbrennung landen. Zusätzlich unterstützt recycling.de Verwertungsverfahren für Mischkunststoffe, bei denen einfache Kunststoffprodukte entstehen.",
        },
        {
          question: "Wie kann ich den Service von recycling.de nutzen?",
          answer:
            "Bitte beachten Sie, dass das Angebot von recycling.de nur für Kunststoffverpackungen gilt.<br /><br />Als Hersteller und Inverkehrbringer von Verkaufsverpackungen sind Sie nach dem VerpackG in Deutschland dazu verpflichtet, Ihre Verpackungen lizenzieren zu lassen und sich einem dualen System anzuschließen, welches dafür sorgt, dass Ihre Verpackungen – sobald sie als Abfall anfallen – gesammelt, sortiert und verwertet werden. <br /><br />Nun ergeben sich zwei Möglichkeiten:<br /><br />1. Haben Sie Ihre Kunststoffverpackungen bereits lizenziert und sind schon Kunde des Dualen Systems Zentek, so können Sie ganz einfach ergänzend die Zusatzleistung von recycling.de buchen. Treten Sie mit uns über das Formular in Kontakt oder sprechen Sie Ihren gewohnten Ansprechpartner an. <br /><br />2. Sie haben Ihre Kunststoffverpackungen noch nicht bei Zentek lizenziert und wollen sich unserem dualen System anschließen? Treten Sie mit uns über das Formular in Kontakt, um beraten zu werden, wie Sie gleichzeitig Kunde des Dualen Systems Zentek werden und von dem Service von recycling.de profitieren.",
        },
        {
          question:
            "Warum gilt das Angebot von recycling.de nur für Kunststoffverpackungen?",
          answer:
            "Das Angebot von recycling.de gilt nur für Kunststoffverpackungen, weil für diese Verpackungen die alternativen Möglichkeiten der energetischen und werkstofflichen Verwertung existieren und aus wirtschaftlichen Gründen große Mengen in der energetischen Verwertung landen.",
        },
        {
          question:
            "Wie setzen sich die Kosten für den Service von recycling.de zusammen?",
          answer:
            "Die dualen Systeme sind verpflichtet, die gesetzlich vorgegebene Kunststoff-Recyclingquote zu erfüllen und werden diesbezüglich behördlich kontrolliert. Die 100-prozentige werkstoffliche Verwertung von Kunststoffen, die recycling.de ermöglicht, bedeutet durch die Nutzung zusätzlicher Verwertungsprozesse einen Extraaufwand mit Extrakosten, für die die finanzielle Beteiligung von Herstellern und Inverkehrbringern notwendig ist. Gemeinsam mit Ihnen ermöglichen wir 100 Prozent Recycling von Kunststoffverpackungen.",
        },
      ],
    };
  },
});
