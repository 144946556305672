/* eslint-disable */
import mitt from "mitt";

const emitter = mitt();

export enum CookieValue {
  ACCEPT = "accepted",
  REJECT = "rejected",
}

/* Validate Input fields functions */

export function validateEmail(email: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
    email
  );
}

export function validatePhone(phone: string) {
  return /^((\+|00)\d\d)? ?(-|\/)? ?(\(\d+\)|\d+) ?-? ?[-/\d ]+(\d|\( ?\d+ ?\))\s?$/.test(
    phone
  );
}

export function validateName(name: string) {
  // for international customers
  return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,30}$/.test(
    name
  );
}

/* Validatation for input fields Rules */

export const emailRules = [
  (email: string) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
      email // eslint-disable-next-line
    ) || "Bitte geben Sie eine gültige E-Mail-Adresse ein", // should be in single quotes
];

export const phoneRules = [
  (value: any) =>
    value === null ||
    /^((\+|00)\d\d)? ?(-|\/)? ?(\(\d+\)|\d+) ?-? ?[-/\d ]+(\d|\( ?\d+ ?\))\s?$/.test(
      value
    ) || // eslint-disable-next-line
    "Bitte geben Sie eine gültige Telefonnummer ein" // should be in single quotes
];

export default {
  validateCaptcha(params: any) {
    emitter.emit("result", params);
  },
};
